import React from "react";
import { useEffect, useState, useRef } from "react";
import Chart from "../modules/Highchart/Chart";
import DataTableHome from "./TableHome";

const Portfolios = () => {
  const [ptf, setPtf] = useState([]);
  const [stats, setStats] = useState(null);
  const [chart, setChart] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [presenceData, setPresenceData] = useState(true);

  // const output_CTALongShort = [];
  // const output_CTALongOnly = [];
  // const output_USUBAMLongOnly = [];
  // const output_JapanLongShort = [];
  // const output_JapanLongOnly = [];
  // const output_Bouygues = [];
  // const output_USGoogleTrend = [];
  // const output_FixedIncome = [];
  // const output_CrossOnly = [];
  // const output_CrossShort = [];
  const output_USAEquity = [];
  // const output_KSA = [];
  const output_Quant = [];
  const output_QuantMini = [];
  const output_FX = [];

  // let CTA_Long_Short = chart["CTA Long Short"];
  // let CTA_Long_Only = chart["CTA Long Only"];
  // let US_UBAM_Long_Only = chart["US UBAM Long Only"];
  // let Japan_Long_Short = chart["Japan Long Short"];
  // let Japan_Long_Only = chart["Japan Long Only"];
  // let Bouygues = chart["Bouygues"];
  // let US_Google_Trend = chart["US Google Trend"];
  // let Fixed_Income = chart["Fixed Income"];
  // let Cross_Asset_Long_Only = chart["Cross Asset Quadrant Long Only"];
  // let Cross_Asset_Long_Short = chart["Cross Asset Quadrant Long Short"];
  let USA_Equity_Market_Neutre = chart["USA Equity Market Neutral"];
  // let Ksa = chart["KSA Equity Long Only"];
  let Quant = chart["Quant"];
  let Quant_Mini = chart["Quant Mini"];
  let fx = chart["FX"];

  const series = [
    // {
    //   name: "CTA Long Short",
    //   data: output_CTALongShort,
    // },
    // {
    //   name: "CTA Long Only",
    //   data: output_CTALongOnly,
    // },
    // {
    //   name: "US UBAM Long Only",
    //   data: output_USUBAMLongOnly,
    // },
    // {
    //   name: "Japan Long Short",
    //   data: output_JapanLongShort,
    // },
    // {
    //   name: "Japan Long Only",
    //   data: output_JapanLongOnly,
    // },
    // {
    //   name: "Bouygues",
    //   data: output_Bouygues,
    // },
    // {
    //   name: "US Google Trend",
    //   data: output_USGoogleTrend,
    // },
    // {
    //   name: "Fixed Income",
    //   data: output_FixedIncome,
    // },
    // {
    //   name: "Cross Asset Quadrant Long Only",
    //   data: output_CrossOnly,
    // },
    // {
    //   name: "Cross Asset Quadrant Long Short",
    //   data: output_CrossShort,
    // },
    {
      name: "USA Equity Market Neutre",
      data: output_USAEquity,
    },
    // {
    //   name: "KSA Equity Long Only",
    //   data: output_KSA,
    // },
    {
      name: "Quant",
      data: output_Quant,
    },
    {
      name: "Quant Mini",
      data: output_QuantMini,
    },
    {
      name: "FX",
      data: output_FX,
    },
  ];

  // for (const property in CTA_Long_Short) {
  //   let datum = Date.parse(property);
  //   let item = [datum + 7200000, CTA_Long_Short[property]];
  //   output_CTALongShort.push(item);
  // }
  // for (const property in CTA_Long_Only) {
  //   let datum = Date.parse(property);
  //   let item = [datum + 7200000, CTA_Long_Only[property]];
  //   output_CTALongOnly.push(item);
  // }
  // for (const property in US_UBAM_Long_Only) {
  //   let datum = Date.parse(property);
  //   let item = [datum+ 7200000, US_UBAM_Long_Only[property]];
  //   output_USUBAMLongOnly.push(item);
  // }
  // for (const property in Japan_Long_Short) {
  //   let datum = Date.parse(property);
  //   let item = [datum+ 7200000, Japan_Long_Short[property]];
  //   output_JapanLongShort.push(item);
  // }
  // for (const property in Japan_Long_Only) {
  //   let datum = Date.parse(property);
  //   let item = [datum+ 7200000, Japan_Long_Only[property]];
  //   output_JapanLongOnly.push(item);
  // }
  // for (const property in Bouygues) {
  //   let datum = Date.parse(property);
  //   let item = [datum + 7200000, Bouygues[property]];
  //   output_Bouygues.push(item);
  // }
  // for (const property in US_Google_Trend) {
  //   let datum = Date.parse(property);
  //   let item = [datum+ 7200000, US_Google_Trend[property]];
  //   output_USGoogleTrend.push(item);
  // }
  // for (const property in Fixed_Income) {
  //   let datum = Date.parse(property);
  //   let item = [datum + 7200000, Fixed_Income[property]];
  //   output_FixedIncome.push(item);
  // }
  // for (const property in Cross_Asset_Long_Only) {
  //   let datum = Date.parse(property);
  //   let item = [datum + 7200000, Cross_Asset_Long_Only[property]];
  //   output_CrossOnly.push(item);
  // }
  // for (const property in Cross_Asset_Long_Short) {
  //   let datum = Date.parse(property);
  //   let item = [datum + 7200000, Cross_Asset_Long_Short[property]];
  //   output_CrossShort.push(item);
  // }
  for (const property in USA_Equity_Market_Neutre) {
    let datum = Date.parse(property);
    let item = [datum + 7200000, USA_Equity_Market_Neutre[property]];
    output_USAEquity.push(item);
  }
  // for (const property in Ksa) {
  //   let datum = Date.parse(property);
  //   let item = [datum + 7200000, Ksa[property]];
  //   output_KSA.push(item);
  // }
  for (const property in Quant) {
    let datum = Date.parse(property);
    let item = [datum + 7200000, Quant[property]];
    output_Quant.push(item);
  }
  for (const property in Quant_Mini) {
    let datum = Date.parse(property);
    let item = [datum + 7200000, Quant_Mini[property]];
    output_QuantMini.push(item);
  }
  for (const property in fx) {
    let datum = Date.parse(property);
    let item = [datum + 7200000, fx[property]];
    output_FX.push(item);
  }

  const scrollToGraph = useRef();
  function toGraph() {
    scrollToGraph.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    const controller = new AbortController();

    async function fetchData() {
      setIsLoading(true);
      try {
        const raw_ptf = await fetch(
          "https://api-alphadash.q3-technology.com/ptf?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );
        const raw_stats = await fetch(
          "https://api-alphadash.q3-technology.com/stats?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );
        const raw_chart = await fetch(
          "https://api-alphadash.q3-technology.com/charts?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );
        const res_ptf = await raw_ptf.json();
        const res_stats = await raw_stats.json();
        const res_chart = await raw_chart.json();

        setPtf(res_ptf.ptf);
        setStats(res_stats.stats);
        setChart(res_chart.charts_data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setPresenceData(false);
      }
    }
    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  return (
    <>
      {presenceData ? (
        <div>
          {isLoading ? (
            <h3>Loading datas...</h3>
          ) : (
            <>
              <div>
                <button onClick={toGraph} className="btn-navbar btn-scroll">
                  Scroll to Graph
                </button>
                {ptf.map((ptf) => {
                  return (
                    <DataTableHome
                      key={ptf.name}
                      ptf={ptf}
                      stats={stats[ptf.name]}
                    />
                  );
                })}
              </div>
              <div ref={scrollToGraph}>
                <Chart
                  title={"STRATEGIES PERFORMANCE"}
                  height={550}
                  series={series}
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          <h3>No data available</h3>
        </div>
      )}
    </>
  );
};
export default Portfolios;
