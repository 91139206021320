import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const darkTheme = createTheme({
  root: {
    width: "max-content",
  },
  palette: {
    mode: "dark",
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        footerContainer: {
          display: "none",
        },
        columnHeaderTitleContainer: {
          padding: "0",
        },
        columnHeaderTitle: {
          fontSize: "12px",
        },
      },
    },
  },
});

const tableHeader = [
  "daily",
  "weekly",
  "1M",
  "3M",
  "6M",
  "12M",
  "36M",
  "ytd",
  "start",
  "ann",
  "vol",
  "sharpe",
  "sharpe 3M",
];

export default function DataTableStrat({ stats }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [data, setData] = useState(null);

  let { ptf } = useParams();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
      headerAlign: "center",
      align: "center",
      cellClassName: "first-column-background",
      headerClassName: "first-column-background",
    },
  ];

  tableHeader.map((row) => {
    return columns.push({
      field: row,
      headerName: row,
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueFormatter: (params) => {
        if (params.field !== "sharpe" && params.field !== "sharpe 3M") {
          if (params.value !== "-") {
            return `${params.value}%`;
          } else return null;
        } else return null;
      },
    });
  });

  let dataRows = [];
  if (data !== null) {
    dataRows = [
      {
        id: "return",
        daily:
          data.strat.daily === 0 ? "-" : (data.strat.daily * 100).toFixed(2),
        weekly:
          data.strat.weekly === 0 ? "-" : (data.strat.weekly * 100).toFixed(2),
        "1M":
          data.strat.one_month === 0
            ? "-"
            : (data.strat.one_month * 100).toFixed(2),
        "3M":
          data.strat.three_month === 0
            ? "-"
            : (data.strat.three_month * 100).toFixed(2),
        "6M":
          data.strat.six_month === 0
            ? "-"
            : (data.strat.six_month * 100).toFixed(2),
        "12M":
          data.strat.twelve_month === 0
            ? "-"
            : (data.strat.twelve_month * 100).toFixed(2),
        "36M":
          data.strat.thirty_six_month === 0
            ? "-"
            : (data.strat.thirty_six_month * 100).toFixed(2),
        ytd: data.strat.ytd === 0 ? "-" : (data.strat.ytd * 100).toFixed(2),
        start: data.strat.std === 0 ? "-" : (data.strat.std * 100).toFixed(2),
        ann: data.strat.ann === 0 ? "-" : (data.strat.ann * 100).toFixed(2),
        vol: (data.strat.vol * 100).toFixed(2),
        sharpe: data.strat.sharpe === 0 ? "-" : data.strat.sharpe.toFixed(2),
        "sharpe 3M":
          data.strat.sharpe_three_month === 0
            ? "-"
            : data.strat.sharpe_three_month.toFixed(2),
      },
      {
        id: "alpha",
        daily:
          ptf === "CTA Long Short" || ptf === "CTA Long Only"
            ? "-"
            : data.strat.daily === 0
            ? "-"
            : (stats.alpha.daily * 100).toFixed(2),
        weekly:
          data.strat.weekly === 0 ? "-" : (stats.alpha.weekly * 100).toFixed(2),
        "1M":
          data.strat.one_month === 0
            ? "-"
            : (stats.alpha.one_month * 100).toFixed(2),
        "3M":
          data.strat.three_month === 0
            ? "-"
            : (stats.alpha.three_month * 100).toFixed(2),
        "6M":
          data.strat.six_month === 0
            ? "-"
            : (stats.alpha.six_month * 100).toFixed(2),
        "12M":
          data.strat.twelve_month === 0
            ? "-"
            : (stats.alpha.twelve_month * 100).toFixed(2),
        "36M":
          data.strat.thirty_six_month === 0
            ? "-"
            : (stats.alpha.thirty_six_month * 100).toFixed(2),
        ytd: data.strat.ytd === 0 ? "-" : (stats.alpha.ytd * 100).toFixed(2),
        start: data.strat.std === 0 ? "-" : (stats.alpha.std * 100).toFixed(2),
        ann: data.strat.ann === 0 ? "-" : (stats.alpha.ann * 100).toFixed(2),
        vol: "-",
        sharpe: "-",
        "sharpe 3M": "-",
      },
      {
        id: "bench",
        daily:
          ptf === "CTA Long Short" || ptf === "CTA Long Only"
            ? "-"
            : data.strat.daily === 0
            ? "-"
            : (stats.bench.daily * 100).toFixed(2),
        weekly:
          data.strat.weekly === 0 ? "-" : (data.bench.weekly * 100).toFixed(2),
        "1M":
          data.strat.one_month === 0
            ? "-"
            : (data.bench.one_month * 100).toFixed(2),
        "3M":
          data.strat.three_month === 0
            ? "-"
            : (data.bench.three_month * 100).toFixed(2),
        "6M":
          data.strat.six_month === 0
            ? "-"
            : (data.bench.six_month * 100).toFixed(2),
        "12M":
          data.strat.twelve_month === 0
            ? "-"
            : (data.bench.twelve_month * 100).toFixed(2),
        "36M":
          data.strat.thirty_six_month === 0
            ? "-"
            : (data.bench.thirty_six_month * 100).toFixed(2),
        ytd: data.strat.ytd === 0 ? "-" : (data.bench.ytd * 100).toFixed(2),
        start: data.strat.std === 0 ? "-" : (data.bench.std * 100).toFixed(2),
        ann: data.strat.ann === 0 ? "-" : (data.bench.ann * 100).toFixed(2),
        vol: (data.bench.vol * 100).toFixed(2),
        sharpe: data.bench.sharpe === 0 ? "-" : data.bench.sharpe.toFixed(2),
        "sharpe 3M":
          data.bench.sharpe_three_month === 0
            ? "-"
            : data.bench.sharpe_three_month.toFixed(2),
      },
    ];
  } else {
    dataRows = [];
  }

  useEffect(() => {
    setIsLoading(true);
    setData(stats);
    setRows(dataRows);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats, data]);

  return (
    <div>
      {isLoading && data ? (
        <h3>Loading datas...</h3>
      ) : (
        <ThemeProvider theme={darkTheme}>
          <Box
            sx={{
              "& .negative": {
                color: "red",
              },
              "& .positive": {
                color: "lightgreen",
              },
              "& .zero": {
                color: "#faf9f9",
              },
              "& .first-column-background": {
                backgroundColor: "#1727477e",
              },
            }}
          >
            <div
              style={{
                height: 214,
                width: "100%",
                marginBottom: 10,
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[100]}
                labelRowsPerPage=""
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: "20px",
                    textAlign: "center",
                  },
                }}
                getCellClassName={(params) => {
                  if (
                    params.field === "id" ||
                    params.field === "sharpe" ||
                    params.field === "sharpe 3M" ||
                    params.field === "vol" ||
                    params.id === "bench"
                  ) {
                    return "";
                  }
                  if (params.value < 0) {
                    return "negative";
                  } else if (params.value > 0) {
                    return "positive";
                  } else return "white";
                }}
              />
            </div>
          </Box>
        </ThemeProvider>
      )}
    </div>
  );
}
