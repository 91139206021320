import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import logo from "./logo carré small.png";
import { useHistory } from "react-router-dom";

const Navbar = ({ title }) => {
  const history = useHistory();
  const [lastUpdate, setLastUpdate] = useState();
  const [isLoading, setIsLoading] = useState(false);

  function logout() {
    localStorage.clear();
    history.push("/login");
  }

  function timeConverter(lastUpdate) {
    let a = new Date(lastUpdate);
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let year = a.getFullYear();
    let month = months[a.getMonth()];
    let date = a.getDate();

    let time = date + " " + month + " " + year;
    return time;
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);

      try {
        const fetchDate = await fetch(
          "https://api-alphadash.q3-technology.com/stats?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );
        const setDate = await fetchDate.json();
        setLastUpdate(setDate.last_update);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <nav className="navbar bg-primary">
      <div className="navbar-content-left">
        <img src={logo} alt="Logo" />
        <h1>
          <a href="/">{title}</a>
        </h1>
        {isLoading ? (
          <p className="last-update">Loading date...</p>
        ) : (
          <p className="last-update">
            Last update : {timeConverter(lastUpdate)}
          </p>
        )}
      </div>
      <div>
        <button onClick={logout} className="btn btn-navbar">
          LOGOUT
        </button>
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  title: "QuantCube Strategies",
};

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Navbar;
