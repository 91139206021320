import React, { Fragment, useRef } from "react";
import Navbar from "./components/layout/Navbar";
import Portfolios from "./components/strats/Portfolios";
import Strats from "./components/strats/Strats";
import Login from "./components/LoginV2";
import SpecificStrat from "./components/strats/SpecificStrat";
import NotFound from "./components/NotFound";
import { Account } from "./components/Account";
import ProtectedRoute from "./components/ProtectedRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";

function App() {
  const scrollToTop = useRef();
  function scrollTop() {
    scrollToTop.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <Router>
      <Fragment>
        <div ref={scrollToTop}></div>
        <Navbar />
        <button className="scroll-top" onClick={scrollTop}>
          TOP
        </button>
        <div className="container">
          <Account>
            <Switch>
              <Route exact path="/login" component={Login} />
              <ProtectedRoute exact path="/" component={Portfolios} />
              <ProtectedRoute path="/strat/:ptf" component={Strats} />
              <ProtectedRoute
                exact
                path="/waterstress"
                component={SpecificStrat}
              />
              <Route component={NotFound} />
            </Switch>
          </Account>
        </div>
      </Fragment>
    </Router>
  );
}

export default App;
