import React from "react";

export default function LiveUs() {
  const [data, setData] = React.useState(null);
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const fetchLiveUs = async () => {
      const controller = new AbortController();
      const timeout = setTimeout(() => {
        controller.abort();
      }, 5000); // Timeout set to 5 seconds

      try {
        const response = await fetch(
          "https://api-alphadash.q3-technology.com/liveus?API_ROUTE_SECRET=gNIlWkTCxVqsZwEa4hRP6Czq8OZisftusYSJaTN0",
          { cache: "no-cache" }
        );
        const data = await response.json();
        setData(data);
        clearTimeout(timeout);
      } catch (error) {
        clearTimeout(timeout);
        throw error;
      }
    };
    fetchLiveUs();

    // Fetch data every 30 seconds
    const intervalId = setInterval(() => {
      fetchLiveUs();
    }, 30000);

    return () => {
      // cleanup
      clearInterval(intervalId);
    };
  }, []);

  const isTotal = data?.alpha + data?.bench + data?.strat;

  return (
    <>
      <button
        className={
          "btn btn-live" +
          (isTotal === 0 ? " background-red" : " background-green")
        }
        onClick={() => setShow(!show)}
      >
        Live
      </button>
      {show ? (
        <table className="table table-liveus">
          <thead className="thead thead-liveus">
            <tr className="tr tr-liveus">
              <th className="th th-liveus">Return</th>
              <th className="th th-liveus">SP 500</th>
              <th className="th th-liveus">Alpha</th>
            </tr>
          </thead>
          <tbody className="tbody tbody-liveus">
            <tr className="tr tr-liveus">
              <td
                className={
                  data?.strat <= 0 || data?.strat === "NaN"
                    ? "td td-liveus red"
                    : "td td-liveus green"
                }
              >
                {(data?.strat * 100).toFixed(2)}%
              </td>
              <td
                className={
                  data?.bench <= 0 ? "td td-liveus red" : "td td-liveus green"
                }
              >
                {(data?.bench * 100).toFixed(2)}%
              </td>
              <td
                className={
                  data?.alpha <= 0 ? "td td-liveus red" : "td td-liveus green"
                }
              >
                {(data?.alpha * 100).toFixed(2)}%
              </td>
            </tr>
          </tbody>
        </table>
      ) : null}
    </>
  );
}
