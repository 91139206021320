import React, { useState, useContext, useEffect } from "react";
import { AccountContext } from "./Account";
import { Redirect, useHistory } from "react-router-dom";
import "./Login.css";

const Login = () => {
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isAuth, setIsAuth] = useState(false);
  const { authenticate, getSession } = useContext(AccountContext);

  useEffect(() => {
    getSession().then((session) => {
      if (session) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (event) => {
    event.preventDefault();
    // console.log(username, password);
    authenticate(username, password)
      .then((data) => {
        // console.log("logged in!", data);
        history.push("/");
      })
      .catch((err) => {
        console.error("Failed to login", err);
      });
  };

  return (
    <>
      {isAuth && <Redirect to="/" />}
      <div className="form-container">
        <form onSubmit={onSubmit}>
          <div className="login_item">
            <label className="login_label" htmlFor="username">
              Username
            </label>
            <input
              className="login_input"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </div>
          <div className="login_item">
            <label className="login_label" htmlFor="password">
              Password
            </label>
            <input
              className="login_input"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              type="password"
            />
          </div>
          <button className="login_btn logout" type="submit">
            LOGIN
          </button>
        </form>
      </div>
    </>
  );
};

export default Login;
