import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import DataTableStrat from "./TableStrat";

export default function ControlledAccordions({ stats }) {
  let { ptf } = useParams();
  const [isLoading, setIsLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    setIsLoading(true);
    setData(stats);
    setIsLoading(false);
  }, [stats]);

  return isLoading && data !== null ? (
    <h3>"Loading strats..."</h3>
  ) : (
    <div className="accordion-container">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          borderRadius: "10px",
          backgroundColor: "rgba(180, 180, 180, 0.1)",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#faf9f9" }} />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            backgroundColor: "#000814",
            border: "1px solid #cfcfcf",
            color: "#faf9f9",
          }}
        >
          <Typography sx={{ flexGrow: 1 }}>{`Metrics ${ptf}`}</Typography>
          <Typography
            sx={{
              color: "#cfcfcf",
            }}
          >
            {expanded ? "" : "Click to expand..."}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataTableStrat nom={ptf} stats={stats[ptf]} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
