import React from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import HighchartsExport from "highcharts/modules/exporting";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import HighchartsAccessibility from "highcharts/modules/accessibility";
// import useSettingsStore from "@stores/settings";

export interface ChartProps {
  title?: string;
  width?: number | null;
  height?: number | null | undefined;
  series?: any;
  theme?: "light";
}

/* This is a TypeScript feature called "type assertion". It is used to tell TypeScript that the
variable is of a certain type. */
if (typeof Highcharts === "object") {
  HighchartsExport(Highcharts);
  HighchartsAccessibility(Highcharts);
  HighchartsNoData(Highcharts);
}

if (typeof Highcharts === "object") {
  Highcharts.setOptions({
    lang: {
      rangeSelectorZoom: "",
    },
  });
}

const Chart = (props: ChartProps): React.ReactElement => {
  const fontColor = "white";

  const logoColor =
    "https://res.cloudinary.com/quantcube-technology/image/upload/v1647014040/QuantCube%20Technology/Q3-logo-crop_cnpo8z.png";

  const options = {
    colors: [
      "#b91c1c",
      "#1d4ed8",
      "#15803d",
      "#eab308",
      "#ea580c",
      "#92400e",
      "#232323",
      "#7dd3fc",
    ],
    title: {
      useHTML: true,
      floating: true,
      text: `<p style="color: ${fontColor}; margin: 0 auto; opacity:0.7; font-size: 1rem">
        ${props.title}</p>`,
      align: "center",
      y: 20,
    },
    subtitle: {
      useHTML: true,
      floating: true,
      // text: `<a href="https://www.quant-cube.com/" target="_blank"><img src=${logoColor}  width="30px" height="30px" style="opacity:0.8"/></a>`,
      align: "right",
      y: props.height ? props.height - 40 : 370,
      x: props.width ? -10 : -20,
    },
    chart: {
      // styledMode: false,
      // backgroundColor: "transparent",
      // width: props.width ? props.width : "",
      height: props.height,
      zoomType: "x",
      backgroundColor: "transparent",
      style: {
        fontFamily: "Roboto",
        color: "white",
      },
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
    },
    lang: {
      noData: "Loading data...",
    },
    exporting: {
      buttons: {
        contextButton: {
          x: -5,
        },
      },
      sourceWidth: 800,
      sourceHeight: 500,
    },
    plotOptions: {
      series: {
        cursor: "pointer",
        pointWidth: 10,

        // events: {
        //   legendItemClick: function (event) {
        //     if (!this.visible) return true

        //     const seriesIndex = this.index
        //     const series = this.chart.series

        //     for (let i = 0; i < series.length; i++) {
        //       if (series[i].index != seriesIndex) {
        //         series[i].visible ? series[i].hide() : series[i].show()
        //       }
        //     }

        //     return false
        //   },
        // },
      },
    },
    xAxis: {
      type: "datetime",
      ordinal: false,
      labels: {
        y: 30,
        style: {
          color: "white",
        },
      },
    },
    tooltip: {
      shared: true,
      pointFormat: `{series.name}: <b>{point.y:.2f}</b><br/>`,
    },
    yAxis: {
      labels: {
        align: "left",
        y: 5,
        style: {
          color: "white",
        },
      },
    },
    legend: {
      enabled: true,
      y: 10,
    },
    navigator: {
      maskFill: "rgba(100, 198, 220, 0.1)",
    },
    // rangeSelector: {
    //   buttonTheme: {
    //     // styles for the buttons
    //     fill: "none",
    //     stroke: "none",
    //     "stroke-width": 0,
    //     r: 8,
    //     style: {
    //       color: "#4B4B4B",
    //     },
    //     states: {
    //       hover: {},
    //       select: {
    //         fill: "#4B4B4B",
    //         style: {
    //           color: "white",
    //         },
    //       },
    //     },
    //   },
    //   inputStyle: {
    //     color: "#4B4B4B",
    //   },
    //   labelStyle: {
    //     color: "silver",
    //   },
    // },
    series: props.series,
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"stockChart"}
      options={options}
    />
  );
};

export default Chart;
