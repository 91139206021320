import { React, useEffect, useState } from "react";

export default function DataTablePositions({
  ptf,
  positions,
  tickernames,
  value,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setIsLoading(false);
    setData(positions[value][ptf]);
    setIsLoading(true);
  }, [ptf, positions, value]);

  return (
    <div>
      {isLoading && positions[value][ptf].expo !== undefined ? (
        <div className="sous-strat--container-expo-top-bot">
          <div className="container-expo">
            <p>Gross Expo : {(data.expo.gross_expo * 100).toFixed(2)}%</p>
            <p>Long Expo : {(data.expo.long_expo * 100).toFixed(2)}%</p>
            <p>Net Expo : {(data.expo.net_expo * 100).toFixed(2)}%</p>
            <p>Short Expo : {(data.expo.short_expo * 100).toFixed(2)}%</p>
          </div>

          <h3>TOP 5 Daily Positions</h3>
          <div className="top-green">
            <div className="container-top">
              {positions[value][ptf].top.length > 0 ? (
                positions[value][ptf].top.map((top) => {
                  if (top[1] !== 0) {
                    return (
                      <div key={top} className="top-bot-data">
                        <p>{tickernames[top[0]]}</p>
                        <span>{(top[1] * 100).toFixed(2)}%</span>
                      </div>
                    );
                  } else return <p>No positions available...</p>;
                })
              ) : (
                <p>No positions available for the moment</p>
              )}
            </div>
          </div>

          <h3>BOTTOM 5 Daily Positions</h3>
          <div className="bot-red">
            <div className="container-bot">
              {positions[value][ptf].bottom.length > 0 ? (
                positions[value][ptf].bottom.map((bot) => {
                  if (bot[1] !== 0) {
                    return (
                      <div key={bot} className="top-bot-data">
                        <p>{tickernames[bot[0]]}</p>
                        <span>{(bot[1] * 100).toFixed(2)}%</span>
                      </div>
                    );
                  } else return "";
                })
              ) : (
                <p>No positions available for the moment</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <h3>Loading tabs...</h3>
      )}
    </div>
  );
}
