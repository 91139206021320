import React, { createContext, useState, useEffect } from "react";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "./UserPool";

const AccountContext = createContext();

const Account = (props) => {
  const [isAuth, setIsAuth] = useState(false);

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            setIsAuth(false);
            reject();
          } else {
            setIsAuth(true);
            resolve(session);
          }
        });
      } else {
        resolve(false);
        setIsAuth(false);
      }
    });
  };
  const authenticate = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username,
        Pool,
      });

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          // console.log("onSuccess: ", data);
          setIsAuth(true);
          resolve(data);
        },
        onFailure: (err) => {
          console.error("onFailure: ", err);
          reject(err);
        },
        // FIRST CONNECTION
        newPasswordRequired: (data) => {
          // console.log("newPasswordRequires: ", data);
          user.completeNewPasswordChallenge(
            Password,
            {},
            {
              onSuccess: (user) => {
                // console.log("success", user);
                setIsAuth(true);
              },
              onFailure: (error) => {
                console.log(error);
              },
            }
          );
          resolve(data);
        },
      });
    });
  };
  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
  };
  useEffect(() => {
    // if session set True
  }, []);
  return (
    <AccountContext.Provider
      value={{ authenticate, getSession, logout, isAuth }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
