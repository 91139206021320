import { React, useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import LiveUs from "../elements/LiveUs";

// Images
import bouygues from "../layout/bouygues.png";
import japon from "../layout/japon.png";
import ubp from "../layout/ubp.png";
import gt_us from "../layout/gt_us.png";
import q3_defaut from "../layout/logo carré small.png";
import usa_flag from "../layout/usa.png";
import saudi_arabia from "../layout/saudi-arabia.png";

const darkTheme = createTheme({
  root: {
    width: "max-content",
  },
  palette: {
    mode: "dark",
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        footerContainer: {
          display: "none",
        },
        columnHeaderTitleContainer: {
          padding: "0",
        },
      },
    },
  },
});

const tableHeader = [
  "daily",
  "weekly",
  "1M",
  "3M",
  "6M",
  "12M",
  "36M",
  "ytd",
  "start",
  "ann",
  "vol",
  "sharpe",
  "sharpe 3M",
];

export default function DataTableHome({ stats, ptf }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  //Images association
  let icon_url = q3_defaut;
  if (ptf.name === "USA Equity Market Neutral") {
    icon_url = usa_flag;
  }
  if (ptf.name === "Bouygues") {
    icon_url = bouygues;
  }
  if (ptf.name === "US UBAM Long Only") {
    icon_url = ubp;
  }
  if ((ptf.name === "Japan Long Only") | (ptf.name === "Japan Long Short")) {
    icon_url = japon;
  }
  if (ptf.name === "US Google Trend") {
    icon_url = gt_us;
  }
  if (ptf.name === "KSA Equity Long Only") {
    icon_url = saudi_arabia;
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
      headerAlign: "center",
      align: "center",
      cellClassName: "first-column-background",
      headerClassName: "first-column-background",
    },
  ];

  tableHeader.map((row) => {
    return columns.push({
      field: row,
      headerName: row,
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueFormatter: (params) => {
        if (params.field !== "sharpe" && params.field !== "sharpe 3M") {
          if (params.value !== "-") {
            return `${params.value}%`;
          } else return null;
        } else return null;
      },
    });
  });

  useEffect(() => {
    setIsLoading(true);

    setRows([
      {
        id: "return",
        daily:
          stats.strat.daily === 0 ? "-" : (stats.strat.daily * 100).toFixed(2),
        weekly:
          stats.strat.weekly === 0
            ? "-"
            : (stats.strat.weekly * 100).toFixed(2),
        "1M":
          stats.strat.one_month === 0
            ? "-"
            : (stats.strat.one_month * 100).toFixed(2),
        "3M":
          stats.strat.three_month === 0
            ? "-"
            : (stats.strat.three_month * 100).toFixed(2),
        "6M":
          stats.strat.six_month === 0
            ? "-"
            : (stats.strat.six_month * 100).toFixed(2),
        "12M":
          stats.strat.twelve_month === 0
            ? "-"
            : (stats.strat.twelve_month * 100).toFixed(2),
        "36M":
          stats.strat.thirty_six_month === 0
            ? "-"
            : (stats.strat.thirty_six_month * 100).toFixed(2),
        ytd: stats.strat.ytd === 0 ? "-" : (stats.strat.ytd * 100).toFixed(2),
        start: stats.strat.std === 0 ? "-" : (stats.strat.std * 100).toFixed(2),
        ann: stats.strat.ann === 0 ? "-" : (stats.strat.ann * 100).toFixed(2),
        vol: stats.strat.vol === 0 ? "-" : (stats.strat.vol * 100).toFixed(2),
        sharpe: stats.strat.sharpe === 0 ? "-" : stats.strat.sharpe.toFixed(2),
        "sharpe 3M":
          stats.strat.sharpe_three_month === 0
            ? "-"
            : stats.strat.sharpe_three_month.toFixed(2),
      },
      {
        id: "alpha",
        daily:
          ptf.name === "CTA Long Short" || ptf.name === "CTA Long Only"
            ? "-"
            : stats.strat.daily === 0
            ? "-"
            : (stats.alpha.daily * 100).toFixed(2),
        weekly:
          stats.strat.weekly === 0
            ? "-"
            : (stats.alpha.weekly * 100).toFixed(2),
        "1M":
          stats.strat.one_month === 0
            ? "-"
            : (stats.alpha.one_month * 100).toFixed(2),
        "3M":
          stats.strat.three_month === 0
            ? "-"
            : (stats.alpha.three_month * 100).toFixed(2),
        "6M":
          stats.strat.six_month === 0
            ? "-"
            : (stats.alpha.six_month * 100).toFixed(2),
        "12M":
          stats.strat.twelve_month === 0
            ? "-"
            : (stats.alpha.twelve_month * 100).toFixed(2),
        "36M":
          stats.strat.thirty_six_month === 0
            ? "-"
            : (stats.alpha.thirty_six_month * 100).toFixed(2),
        ytd: stats.strat.ytd === 0 ? "-" : (stats.alpha.ytd * 100).toFixed(2),
        start: stats.strat.std === 0 ? "-" : (stats.alpha.std * 100).toFixed(2),
        ann: stats.strat.ann === 0 ? "-" : (stats.alpha.ann * 100).toFixed(2),
        vol: "-",
        sharpe: "-",
        "sharpe 3M": "-",
      },
      {
        id: "bench",
        daily:
          ptf.name === "CTA Long Short" || ptf.name === "CTA Long Only"
            ? "-"
            : stats.strat.daily === 0
            ? "-"
            : (stats.bench.daily * 100).toFixed(2),
        weekly:
          stats.strat.weekly === 0
            ? "-"
            : (stats.bench.weekly * 100).toFixed(2),
        "1M":
          stats.strat.one_month === 0
            ? "-"
            : (stats.bench.one_month * 100).toFixed(2),
        "3M":
          stats.strat.three_month === 0
            ? "-"
            : (stats.bench.three_month * 100).toFixed(2),
        "6M":
          stats.strat.six_month === 0
            ? "-"
            : (stats.bench.six_month * 100).toFixed(2),
        "12M":
          stats.strat.twelve_month === 0
            ? "-"
            : (stats.bench.twelve_month * 100).toFixed(2),
        "36M":
          stats.strat.thirty_six_month === 0
            ? "-"
            : (stats.bench.thirty_six_month * 100).toFixed(2),
        ytd: stats.strat.ytd === 0 ? "-" : (stats.bench.ytd * 100).toFixed(2),
        start: stats.strat.std === 0 ? "-" : (stats.bench.std * 100).toFixed(2),
        ann: stats.strat.ann === 0 ? "-" : (stats.bench.ann * 100).toFixed(2),
        vol: stats.strat.vol === 0 ? "-" : (stats.bench.vol * 100).toFixed(2),
        sharpe: stats.strat.sharpe === 0 ? "-" : stats.bench.sharpe.toFixed(2),
        "sharpe 3M":
          stats.strat.sharpe_three_month === 0
            ? "-"
            : stats.bench.sharpe_three_month.toFixed(2),
      },
    ]);

    setIsLoading(false);
  }, [stats, ptf.name]);

  return (
    <div>
      {isLoading && stats ? (
        <h3>Loading datas...</h3>
      ) : (
        <>
          <div className="nom_strat">
            <Link
              to={"/strat/" + ptf.name}
              className={`btn btn-title ${stats ? "" : "isDisabled"}`}
            >
              <img src={icon_url} alt="" className="round-img" />
              <h3>{ptf.name}</h3>
            </Link>
            {ptf.name === "USA Equity Market Neutral" ? <LiveUs /> : null}
          </div>
          <ThemeProvider theme={darkTheme}>
            <Box
              sx={{
                "& .negative": {
                  color: "red",
                },
                "& .positive": {
                  color: "lightgreen",
                },
                "& .zero": {
                  color: "#faf9f9",
                },
                "& .first-column-background": {
                  backgroundColor: "#1727477e",
                },
              }}
            >
              <div
                style={{
                  height: 214,
                  width: "100%",
                  marginBottom: 50,
                }}
              >
                <DataGrid
                  rows={rows}
                  columns={columns}
                  rowsPerPageOptions={[100]}
                  labelRowsPerPage=""
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      textOverflow: "clip",
                      whiteSpace: "break-spaces",
                      lineHeight: "20px",
                      textAlign: "center",
                    },
                  }}
                  getCellClassName={(params) => {
                    if (
                      params.field === "id" ||
                      params.field === "sharpe" ||
                      params.field === "sharpe 3M" ||
                      params.field === "vol" ||
                      params.id === "bench"
                    ) {
                      return "";
                    }
                    if (params.value < 0) {
                      return "negative";
                    } else if (params.value > 0) {
                      return "positive";
                    } else return "white";
                  }}
                />
              </div>
            </Box>
          </ThemeProvider>
        </>
      )}
    </div>
  );
}
