import { React, useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const darkTheme = createTheme({
  root: {
    width: "max-content",
  },
  palette: {
    mode: "dark",
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        footerContainer: {
          display: "none",
        },
        columnHeaderTitleContainer: {
          padding: "0",
        },
        columnHeaderTitle: {
          fontSize: "12px",
        },
      },
    },
  },
});

const tableHeader = [
  "weekly",
  "1M",
  "3M",
  "6M",
  "12M",
  "36M",
  "start",
  "ann",
  "vol",
  "sharpe",
  "sharpe 3M",
];

export default function DataTableSpecific(data) {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const perf = data.stats;

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 60,
      headerAlign: "center",
      align: "center",
      cellClassName: "first-column-background",
      headerClassName: "first-column-background",
    },
  ];

  tableHeader.map((row) => {
    return columns.push({
      field: row,
      headerName: row,
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueFormatter: (params) => {
        if (params.field !== "sharpe" && params.field !== "sharpe 3M") {
          if (params.value !== "-") {
            return `${params.value}%`;
          } else return null;
        } else return null;
      },
    });
  });

  let dataRows = [];
  if (perf !== null) {
    dataRows = [
      {
        id: "return",
        daily:
          perf.strat.daily === 0 ? "-" : (perf.strat.daily * 100).toFixed(2),
        weekly:
          perf.strat.weekly === 0 ? "-" : (perf.strat.weekly * 100).toFixed(2),
        "1M":
          perf.strat["1M"] === 0 ? "-" : (perf.strat["1M"] * 100).toFixed(2),
        "3M":
          perf.strat["3M"] === 0 ? "-" : (perf.strat["3M"] * 100).toFixed(2),
        "6M":
          perf.strat["6M"] === 0 ? "-" : (perf.strat["6M"] * 100).toFixed(2),
        "12M":
          perf.strat["12M"] === 0 ? "-" : (perf.strat["12M"] * 100).toFixed(2),
        "36M":
          perf.strat["36M"] === 0 ? "-" : (perf.strat["36M"] * 100).toFixed(2),

        start:
          perf.strat.start === 0 ? "-" : (perf.strat.start * 100).toFixed(2),
        ann: perf.strat.ann === 0 ? "-" : (perf.strat.ann * 100).toFixed(2),
        vol: (perf.strat.vol * 100).toFixed(2),
        sharpe: perf.strat.sharpe.toFixed(2),
        "sharpe 3M": perf.strat["sharpe 3M"].toFixed(2),
      },
      {
        id: "alpha",
        daily:
          perf.strat.daily === 0 ? "-" : (perf.alpha.daily * 100).toFixed(2),
        weekly:
          perf.strat.weekly === 0 ? "-" : (perf.alpha.weekly * 100).toFixed(2),
        "1M":
          perf.strat["1M"] === 0 ? "-" : (perf.alpha["1M"] * 100).toFixed(2),
        "3M":
          perf.strat["3M"] === 0 ? "-" : (perf.alpha["3M"] * 100).toFixed(2),
        "6M":
          perf.strat["6M"] === 0 ? "-" : (perf.alpha["6M"] * 100).toFixed(2),
        "12M":
          perf.strat["12M"] === 0 ? "-" : (perf.alpha["12M"] * 100).toFixed(2),
        "36M":
          perf.strat["36M"] === 0 ? "-" : (perf.alpha["36M"] * 100).toFixed(2),

        start:
          perf.strat.start === 0 ? "-" : (perf.alpha.start * 100).toFixed(2),
        ann: perf.strat.ann === 0 ? "-" : (perf.alpha.ann * 100).toFixed(2),
        vol: "-",
        sharpe: "-",
        "sharpe 3M": "-",
      },
      {
        id: "wheat",
        daily:
          perf.strat.daily === 0 ? "-" : (perf.bench.daily * 100).toFixed(2),
        weekly:
          perf.strat.weekly === 0 ? "-" : (perf.bench.weekly * 100).toFixed(2),
        "1M":
          perf.strat["1M"] === 0 ? "-" : (perf.bench["1M"] * 100).toFixed(2),
        "3M":
          perf.strat["3M"] === 0 ? "-" : (perf.bench["3M"] * 100).toFixed(2),
        "6M":
          perf.strat["6M"] === 0 ? "-" : (perf.bench["6M"] * 100).toFixed(2),
        "12M":
          perf.strat["12M"] === 0 ? "-" : (perf.bench["12M"] * 100).toFixed(2),
        "36M":
          perf.strat["36M"] === 0 ? "-" : (perf.bench["36M"] * 100).toFixed(2),

        start:
          perf.strat.start === 0 ? "-" : (perf.bench.start * 100).toFixed(2),
        ann: perf.strat.ann === 0 ? "-" : (perf.bench.ann * 100).toFixed(2),
        vol: (perf.bench.vol * 100).toFixed(2),
        sharpe: perf.bench.sharpe.toFixed(2),
        "sharpe 3M": perf.bench["sharpe 3M"].toFixed(2),
      },
    ];
  } else {
    dataRows = [];
  }

  useEffect(() => {
    setIsLoading(true);
    setRows(dataRows);
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoading ? (
        <h3>Loading datas...</h3>
      ) : (
        <ThemeProvider theme={darkTheme}>
          <Box
            sx={{
              "& .negative": {
                color: "red",
              },
              "& .positive": {
                color: "lightgreen",
              },
              "& .zero": {
                color: "#faf9f9",
              },
              "& .first-column-background": {
                backgroundColor: "#1727477e",
              },
            }}
          >
            <div
              style={{
                height: 214,
                width: "100%",
                marginBottom: 10,
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[100]}
                labelRowsPerPage=""
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: "20px",
                    textAlign: "center",
                  },
                }}
                getCellClassName={(params) => {
                  if (
                    params.field === "id" ||
                    params.field === "sharpe" ||
                    params.field === "sharpe 3M" ||
                    params.field === "vol" ||
                    params.id === "bench"
                  ) {
                    return "";
                  }
                  if (params.value < 0) {
                    return "negative";
                  } else if (params.value > 0) {
                    return "positive";
                  } else return "white";
                }}
              />
            </div>
          </Box>
        </ThemeProvider>
      )}
    </div>
  );
}
