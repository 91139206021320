import React from "react";
import { useEffect, useState } from "react";

import Chart from "../modules/Highchart/Chart";
import DataTableSpecific from "./TableSpecific";

const SpecificStrat = () => {
  //Initialing states
  const [isLoading, setIsLoading] = useState(false);
  const [chartPerf, setChartPerf] = useState();
  const [chartSignal, setChartSignal] = useState();
  const [perf, setPerf] = useState();
  const [expo, setExpo] = useState();

  // console.log("Perf fetch : ", perf);

  const output_chart_perf = [];
  const output_chart_signal = [];

  const seriesPerf = [
    {
      name: "QuantCube Wheat Strategy",
      data: output_chart_perf,
    },
  ];

  // const seriesSignal = [
  //   {
  //     name: "Waterstress Signal",
  //     data: output_chart_signal,
  //   },
  // ];

  for (const property in chartPerf) {
    let datum = Date.parse(property);
    let item = [datum + 7200000, chartPerf[property]];
    output_chart_perf.push(item);
  }
  for (const property in chartSignal) {
    let datum = Date.parse(property);
    let item = [datum + 7200000, chartSignal[property]];
    output_chart_signal.push(item);
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const data = await fetch(
          "https://api-alphadash.q3-technology.com/waterstress?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );
        const response = await data.json();

        setChartPerf(response.waterstress.chart_perf);
        setChartSignal(response.waterstress.chart_signal);
        setPerf(response.waterstress.tableau_perf);
        setExpo(response.waterstress.expo);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <h1>QuantCube Wheat Strategy</h1>
      {isLoading ? (
        <h3>Loading datas...</h3>
      ) : (
        <div className="strats-container">
          <div className="div_positions_strat">
            {/* {expo && (
              <div className="sous-strat--container-expo-top-bot">
                <div className="container-expo">
                  <p>Gross Expo : {(expo.gross_expo * 100).toFixed(2)}%</p>
                  <p>Long Expo : {(expo.long_expo * 100).toFixed(2)}%</p>
                  <p>Net Expo : {(expo.net_expo * 100).toFixed(2)}%</p>
                  <p>Short Expo : {(expo.short_expo * 100).toFixed(2)}%</p>
                </div>
              </div>
            )} */}
            {expo && (
              <div className="div_expo">
                <h3 style={{ marginTop: "2rem" }}>Today Order</h3>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th style={{ fontSize: "0.8rem" }}>Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* <tr>
                      <td>Corn Future Sept 2022</td>
                      <td>-41.06%</td>
                    </tr> */}
                    <tr>
                      <td>Wheat Future Dec 2022</td>
                      <td style={{ textAlign: "center" }}>80%</td>
                    </tr>
                  </tbody>
                </table>
                {/* <h3 style={{ marginTop: "2rem" }}>Yesterday</h3>
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th style={{ fontSize: "0.8rem" }}>Contrib</th>
                      <th style={{ fontSize: "0.8rem" }}>Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Corn</td>
                      <td>{expo.yesterday.Corn.contrib}%</td>
                      <td>{expo.yesterday.Corn.weight}%</td>
                    </tr>
                    <tr>
                      <td>Wheat</td>
                      <td>{expo.yesterday.Wheat.contrib}%</td>
                      <td>{expo.yesterday.Wheat.weight}%</td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
            )}
          </div>
          <div className="div_positions_strat_right">
            {perf && (
              <DataTableSpecific
                nom={"QuantCube Wheat Strategy"}
                stats={perf}
              />
            )}
            <br />
            <br />
            <br />

            <Chart
              title={"QuantCube Wheat Strategy"}
              series={seriesPerf}
              height={500}
            />
            {/* <br />
            <br />
            <br />
            <StockChartCustom
              title={"Signal"}
              series={seriesSignal}
              height={500}
            /> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default SpecificStrat;
