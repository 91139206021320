import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

const poolData = {
  UserPoolId: "eu-west-3_kjf3YcIKo",
  ClientId: "5sc3okptgs9nk19g9flm0jfm1e",
};

const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export default userPool;
