import React from "react";

const StratsItem = ({ nom, positions, tickernames, value }) => {
  return (
    <div className="div_expo">
      <h3>
        {positions[value][nom].length < 1 ? "" : nom.split("_").join(" ")}
      </h3>
      {positions[value][nom].length < 1 ? (
        ""
      ) : (
        <div className="titles-position">
          <p className="p-weigh-contrib">Weight</p>
          {value === "yesterday" ? (
            <p className="p-weigh-contrib">Contrib</p>
          ) : null}
        </div>
      )}
      <table className="tableau_expo">
        <tbody>
          {positions[value]
            ? positions[value][nom].map((x) => {
                if (x[1] !== 0) {
                  return (
                    <tr key={x}>
                      <td className="colonne_nom">{tickernames[x[0]]}</td>
                      <td className="colonne_weight">
                        {(x[1] * 100).toFixed(2)}%
                      </td>
                      {x.length !== 2 ? (
                        <td
                          className={
                            x[2] < 0
                              ? "colonne_contrib red"
                              : "colonne_contrib green"
                          }
                        >
                          {(x[2] * 100).toFixed(2)}%
                        </td>
                      ) : null}
                    </tr>
                  );
                } else return null;
              })
            : "Error loading..."}
        </tbody>
      </table>
    </div>
  );
};

export default StratsItem;
