import Chart from "../modules/Highchart/Chart";
import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import StratsItem from "./StratsItem";
import DataTable from "./Table";
import ControlledAccordions from "./Accordion";
import DataTablePositions from "./TablePositions";

import { createTheme, ThemeProvider } from "@mui/material/styles";

const darkTheme = createTheme({
  root: {
    width: "max-content",
  },
  palette: {
    mode: "dark",
  },
});

const Strats = () => {
  let { ptf } = useParams();

  const [ptfs, setPtf] = useState([]);
  const [positions, setPositions] = useState([]);
  const [tickernames, setTickernames] = useState([]);
  const [stats, setStats] = useState(null);
  const [, setPerf] = useState([]);
  const [series, setSeries] = useState([]);
  const [, setListStrat] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState("today");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  async function listSousStrats(stats) {
    return Object.keys(stats[ptf]["sous_strats"]);
  }

  async function parseStrat(listStrat, perf) {
    return listStrat.map((nameSelected) => {
      const outputData = [];
      for (const property in perf[nameSelected]) {
        let datum = Date.parse(property);
        let item = [datum + 7200000, perf[nameSelected][property]];
        outputData.push(item);
      }
      return {
        name: nameSelected.split("_").join(" "),
        data: outputData,
      };
    });
  }

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const raw_stats = await fetch(
          "https://api-alphadash.q3-technology.com/stats?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );
        const raw_positions = await fetch(
          "https://api-alphadash.q3-technology.com/positions?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );
        const raw_ptfs = await fetch(
          "https://api-alphadash.q3-technology.com/ptf?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );
        const raw_name = await fetch(
          "https://api-alphadash.q3-technology.com/names?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );
        const raw_perf = await fetch(
          "https://api-alphadash.q3-technology.com/charts?" +
            new URLSearchParams({
              API_ROUTE_SECRET: process.env.REACT_APP_API_ROUTE_SECRET,
            })
        );

        const res_stats = await raw_stats.json();
        const res_positions = await raw_positions.json();
        const res_ptfs = await raw_ptfs.json();
        const res_name = await raw_name.json();
        const res_perf = await raw_perf.json();

        const res_sous_strats = await listSousStrats(res_stats.stats);
        const res_series = await parseStrat(
          res_sous_strats,
          res_perf.charts_data
        );

        setStats(res_stats.stats);
        setPositions(res_positions.positions);
        setPtf(res_ptfs.ptf);
        setTickernames(res_name.names);
        setPerf(res_perf.charts_data);
        setListStrat(res_sous_strats);
        setSeries(res_series);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isLoading ? (
        <h3>Loading datas...</h3>
      ) : (
        <>
          <Link to={"/"}>
            <button className="btn btn-back-home">&lsaquo; Back home</button>
          </Link>
          <h1>{ptf}</h1>
          <div className="strats-container">
            <div className="div_positions_strat">
              <ThemeProvider theme={darkTheme}>
                <div className="radio-group">
                  <FormControl>
                    <FormLabel id="demo-controlled-radio-buttons-group">
                      Select date
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={handleChange}
                      row
                    >
                      <FormControlLabel
                        value="today"
                        control={<Radio />}
                        label="Today"
                      />
                      <FormControlLabel
                        value="yesterday"
                        control={<Radio />}
                        label="Yesterday"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </ThemeProvider>
              {positions[value] !== undefined && ptf ? (
                <DataTablePositions
                  ptf={ptf}
                  positions={positions}
                  tickernames={tickernames}
                  value={value}
                />
              ) : null}
              {ptfs.map((el) => {
                if (el.name === ptf) {
                  return el.strat.map((s) => (
                    <StratsItem
                      key={`id-strat-${s}`}
                      nom={s}
                      positions={positions}
                      tickernames={tickernames}
                      value={value}
                    />
                  ));
                } else {
                  return "";
                }
              })}
            </div>
            <div className="div_positions_strat_right">
              {ptfs && stats ? (
                <ControlledAccordions ptf={ptf} stats={stats} />
              ) : (
                <h3>Loading datas...</h3>
              )}

              {ptfs && stats ? (
                ptfs.map((el) => {
                  if (el.name === ptf) {
                    return el.strat.map((nom) => (
                      <DataTable key={nom} nom={nom} stats={stats[ptf]} />
                    ));
                  } else {
                    return "";
                  }
                })
              ) : (
                <h3>Loading datas...</h3>
              )}
              {series ? (
                <Chart
                  title={"SUB-STRATEGY PERFORMANCES"}
                  series={series}
                  height={500}
                />
              ) : (
                "No data for graph yet..."
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Strats;
