import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const darkTheme = createTheme({
  root: {
    width: "max-content",
  },
  palette: {
    mode: "dark",
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        footerContainer: {
          display: "none",
        },
        columnHeaderTitleContainer: {
          padding: "0",
        },
        columnHeaderTitle: {
          fontSize: "12px",
        },
      },
    },
  },
});

const tableHeader = [
  "daily",
  "weekly",
  "1M",
  "3M",
  "6M",
  "12M",
  "36M",
  "ytd",
  "start",
  "ann",
  "vol",
  "sharpe",
  "sharpe 3M",
];

export default function DataTable({ nom, stats }) {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  let { ptf } = useParams();

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
      fontWeight: "bold",
      cellClassName: "first-column-background",
      headerClassName: "first-column-background",
    },
  ];

  tableHeader.map((row) => {
    return columns.push({
      field: row,
      headerName: row,
      headerAlign: "center",
      align: "center",
      padding: "0",
      flex: 1,
      valueFormatter: (params) => {
        if (params.field !== "sharpe" && params.field !== "sharpe 3M") {
          if (params.value !== "-") {
            return `${params.value}%`;
          } else return null;
        } else return null;
      },
    });
  });

  useEffect(() => {
    setIsLoading(true);

    setRows([
      {
        id: "return",
        daily:
          stats.sous_strats[nom].daily === 0
            ? "-"
            : (stats.sous_strats[nom].daily * 100).toFixed(2),
        weekly:
          stats.sous_strats[nom].weekly === 0
            ? "-"
            : (stats.sous_strats[nom].weekly * 100).toFixed(2),
        "1M":
          stats.sous_strats[nom].one_month === 0
            ? "-"
            : (stats.sous_strats[nom].one_month * 100).toFixed(2),
        "3M":
          stats.sous_strats[nom].three_month === 0
            ? "-"
            : (stats.sous_strats[nom].three_month * 100).toFixed(2),
        "6M":
          stats.sous_strats[nom].six_month === 0
            ? "-"
            : (stats.sous_strats[nom].six_month * 100).toFixed(2),
        "12M":
          stats.sous_strats[nom].twelve_month === 0
            ? "-"
            : (stats.sous_strats[nom].twelve_month * 100).toFixed(2),
        "36M":
          stats.sous_strats[nom].thirty_six_month === 0
            ? "-"
            : (stats.sous_strats[nom].thirty_six_month * 100).toFixed(2),
        ytd:
          stats.sous_strats[nom].ytd === 0
            ? "-"
            : (stats.sous_strats[nom].ytd * 100).toFixed(2),
        start:
          stats.sous_strats[nom].std === 0
            ? "-"
            : (stats.sous_strats[nom].std * 100).toFixed(2),
        ann:
          stats.sous_strats[nom].ann === 0
            ? "-"
            : (stats.sous_strats[nom].ann * 100).toFixed(2),
        vol: (stats.sous_strats[nom].vol * 100).toFixed(2),
        sharpe: stats.sous_strats[nom].sharpe.toFixed(2),
        "sharpe 3M": stats.sous_strats[nom].sharpe_three_month.toFixed(2),
      },
      {
        id: "alpha",
        daily: nom.includes("CTA")
          ? "-"
          : stats.sous_strats[nom].daily === 0
          ? "-"
          : ((stats.sous_strats[nom].daily - stats.bench.daily) * 100).toFixed(
              2
            ),
        weekly:
          stats.sous_strats[nom].weekly === 0
            ? "-"
            : (
                (stats.sous_strats[nom].weekly - stats.bench.weekly) *
                100
              ).toFixed(2),
        "1M":
          stats.sous_strats[nom].one_month === 0
            ? "-"
            : (
                (stats.sous_strats[nom].one_month - stats.bench.one_month) *
                100
              ).toFixed(2),
        "3M":
          stats.sous_strats[nom].three_month === 0
            ? "-"
            : (
                (stats.sous_strats[nom].three_month - stats.bench.three_month) *
                100
              ).toFixed(2),
        "6M":
          stats.sous_strats[nom].six_month === 0
            ? "-"
            : (
                (stats.sous_strats[nom].six_month - stats.bench.six_month) *
                100
              ).toFixed(2),
        "12M":
          stats.sous_strats[nom].twelve_month === 0
            ? "-"
            : (
                (stats.sous_strats[nom].twelve_month -
                  stats.bench.twelve_month) *
                100
              ).toFixed(2),
        "36M":
          stats.sous_strats[nom].thirty_six_month === 0
            ? "-"
            : (
                (stats.sous_strats[nom].thirty_six_month -
                  stats.bench.thirty_six_month) *
                100
              ).toFixed(2),
        ytd:
          stats.sous_strats[nom].ytd === 0
            ? "-"
            : ((stats.sous_strats[nom].ytd - stats.bench.ytd) * 100).toFixed(2),
        start:
          stats.sous_strats[nom].std === 0
            ? "-"
            : ((stats.sous_strats[nom].std - stats.bench.std) * 100).toFixed(2),
        ann:
          stats.sous_strats[nom].ann === 0
            ? "-"
            : ((stats.sous_strats[nom].ann - stats.bench.ann) * 100).toFixed(2),
        vol: ((stats.sous_strats[nom].vol - stats.bench.vol) * 100).toFixed(2),
        sharpe: (stats.sous_strats[nom].sharpe - stats.bench.sharpe).toFixed(2),
        "sharpe 3M": (
          stats.sous_strats[nom].sharpe_three_month -
          stats.bench.sharpe_three_month
        ).toFixed(2),
      },
      {
        id: "bench",
        daily: nom.includes("CTA")
          ? "-"
          : stats.sous_strats[nom].daily === 0
          ? "-"
          : (stats.bench.daily * 100).toFixed(2),
        weekly:
          stats.sous_strats[nom].weekly === 0
            ? "-"
            : (stats.bench.weekly * 100).toFixed(2),
        "1M":
          stats.sous_strats[nom].one_month === 0
            ? "-"
            : (stats.bench.one_month * 100).toFixed(2),
        "3M":
          stats.sous_strats[nom].three_month === 0
            ? "-"
            : (stats.bench.three_month * 100).toFixed(2),
        "6M":
          stats.sous_strats[nom].six_month === 0
            ? "-"
            : (stats.bench.six_month * 100).toFixed(2),
        "12M":
          stats.sous_strats[nom].twelve_month === 0
            ? "-"
            : (stats.bench.twelve_month * 100).toFixed(2),
        "36M":
          stats.sous_strats[nom].thirty_six_month === 0
            ? "-"
            : (stats.bench.thirty_six_month * 100).toFixed(2),
        ytd:
          stats.sous_strats[nom].ytd === 0
            ? "-"
            : (stats.bench.ytd * 100).toFixed(2),
        start:
          stats.sous_strats[nom].std === 0
            ? "-"
            : (stats.bench.std * 100).toFixed(2),
        ann:
          stats.sous_strats[nom].ann === 0
            ? "-"
            : (stats.bench.ann * 100).toFixed(2),
        vol: (stats.bench.vol * 100).toFixed(2),
        sharpe: stats.bench.sharpe.toFixed(2),
        "sharpe 3M": stats.bench.sharpe_three_month.toFixed(2),
      },
    ]);

    setIsLoading(false);
  }, [nom, stats, ptf]);

  useEffect(() => {
    setIsLoading(true);
    if (ptf === "USA Equity Market Neutre") {
      setRows([
        {
          id: "return",
          daily:
            stats.sous_strats[nom].daily === 0
              ? "-"
              : (stats.sous_strats[nom].daily * 100).toFixed(2),
          weekly:
            stats.sous_strats[nom].weekly === 0
              ? "-"
              : (stats.sous_strats[nom].weekly * 100).toFixed(2),
          "1M":
            stats.sous_strats[nom].one_month === 0
              ? "-"
              : (stats.sous_strats[nom].one_month * 100).toFixed(2),
          "3M":
            stats.sous_strats[nom].three_month === 0
              ? "-"
              : (stats.sous_strats[nom].three_month * 100).toFixed(2),
          "6M":
            stats.sous_strats[nom].six_month === 0
              ? "-"
              : (stats.sous_strats[nom].six_month * 100).toFixed(2),
          "12M":
            stats.sous_strats[nom].twelve_month === 0
              ? "-"
              : (stats.sous_strats[nom].twelve_month * 100).toFixed(2),
          "36M":
            stats.sous_strats[nom].thirty_six_month === 0
              ? "-"
              : (stats.sous_strats[nom].thirty_six_month * 100).toFixed(2),
          ytd:
            stats.sous_strats[nom].ytd === 0
              ? "-"
              : (stats.sous_strats[nom].ytd * 100).toFixed(2),
          start:
            stats.sous_strats[nom].std === 0
              ? "-"
              : (stats.sous_strats[nom].std * 100).toFixed(2),
          ann:
            stats.sous_strats[nom].ann === 0
              ? "-"
              : (stats.sous_strats[nom].ann * 100).toFixed(2),
          vol: (stats.sous_strats[nom].vol * 100).toFixed(2),
          sharpe: stats.sous_strats[nom].sharpe.toFixed(2),
          "sharpe 3M": stats.sous_strats[nom].sharpe_three_month.toFixed(2),
        },
        {
          id: "alpha",
          daily: nom.includes("CTA")
            ? "-"
            : stats.sous_strats[nom].daily === 0
            ? "-"
            : (
                (stats.sous_strats[nom].daily - stats.bench.daily) *
                100
              ).toFixed(2),
          weekly:
            stats.sous_strats[nom].weekly === 0
              ? "-"
              : (
                  (stats.sous_strats[nom].weekly - stats.bench.weekly) *
                  100
                ).toFixed(2),
          "1M":
            stats.sous_strats[nom].one_month === 0
              ? "-"
              : (
                  (stats.sous_strats[nom].one_month - stats.bench.one_month) *
                  100
                ).toFixed(2),
          "3M":
            stats.sous_strats[nom].three_month === 0
              ? "-"
              : (
                  (stats.sous_strats[nom].three_month -
                    stats.bench.three_month) *
                  100
                ).toFixed(2),
          "6M":
            stats.sous_strats[nom].six_month === 0
              ? "-"
              : (
                  (stats.sous_strats[nom].six_month - stats.bench.six_month) *
                  100
                ).toFixed(2),
          "12M":
            stats.sous_strats[nom].twelve_month === 0
              ? "-"
              : (
                  (stats.sous_strats[nom].twelve_month -
                    stats.bench.twelve_month) *
                  100
                ).toFixed(2),
          "36M":
            stats.sous_strats[nom].thirty_six_month === 0
              ? "-"
              : (
                  (stats.sous_strats[nom].thirty_six_month -
                    stats.bench.thirty_six_month) *
                  100
                ).toFixed(2),
          ytd:
            stats.sous_strats[nom].ytd === 0
              ? "-"
              : ((stats.sous_strats[nom].ytd - stats.bench.ytd) * 100).toFixed(
                  2
                ),
          start:
            stats.sous_strats[nom].std === 0
              ? "-"
              : ((stats.sous_strats[nom].std - stats.bench.std) * 100).toFixed(
                  2
                ),
          ann:
            stats.sous_strats[nom].ann === 0
              ? "-"
              : ((stats.sous_strats[nom].ann - stats.bench.ann) * 100).toFixed(
                  2
                ),
          vol: ((stats.sous_strats[nom].vol - stats.bench.vol) * 100).toFixed(
            2
          ),
          sharpe: (stats.sous_strats[nom].sharpe - stats.bench.sharpe).toFixed(
            2
          ),
          "sharpe 3M": (
            stats.sous_strats[nom].sharpe_three_month -
            stats.bench.sharpe_three_month
          ).toFixed(2),
        },
        {
          id: "bench",
          daily: nom.includes("CTA")
            ? "-"
            : stats.sous_strats[nom].daily === 0
            ? "-"
            : (stats.bench.daily * 100).toFixed(2),
          weekly:
            stats.sous_strats[nom].weekly === 0
              ? "-"
              : (stats.bench.weekly * 100).toFixed(2),
          "1M":
            stats.sous_strats[nom].one_month === 0
              ? "-"
              : (stats.bench.one_month * 100).toFixed(2),
          "3M":
            stats.sous_strats[nom].three_month === 0
              ? "-"
              : (stats.bench.three_month * 100).toFixed(2),
          "6M":
            stats.sous_strats[nom].six_month === 0
              ? "-"
              : (stats.bench.six_month * 100).toFixed(2),
          "12M":
            stats.sous_strats[nom].twelve_month === 0
              ? "-"
              : (stats.bench.twelve_month * 100).toFixed(2),
          "36M":
            stats.sous_strats[nom].thirty_six_month === 0
              ? "-"
              : (stats.bench.thirty_six_month * 100).toFixed(2),
          ytd:
            stats.sous_strats[nom].ytd === 0
              ? "-"
              : (stats.bench.ytd * 100).toFixed(2),
          start:
            stats.sous_strats[nom].std === 0
              ? "-"
              : (stats.bench.std * 100).toFixed(2),
          ann:
            stats.sous_strats[nom].ann === 0
              ? "-"
              : (stats.bench.ann * 100).toFixed(2),
          vol: (stats.bench.vol * 100).toFixed(2),
          sharpe: stats.bench.sharpe.toFixed(2),
          "sharpe 3M": stats.bench.sharpe_three_month.toFixed(2),
        },
        {
          id: "contrib",
          daily:
            stats.sous_strats[nom].contrib.daily === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.daily * 100).toFixed(2),
          weekly:
            stats.sous_strats[nom].contrib.weekly === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.weekly * 100).toFixed(2),
          "1M":
            stats.sous_strats[nom].contrib.one_month === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.one_month * 100).toFixed(2),
          "3M":
            stats.sous_strats[nom].contrib.three_month === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.three_month * 100).toFixed(2),
          "6M":
            stats.sous_strats[nom].contrib.six_month === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.six_month * 100).toFixed(2),
          "12M":
            stats.sous_strats[nom].contrib.twelve_month === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.twelve_month * 100).toFixed(2),
          "36M":
            stats.sous_strats[nom].contrib.thirty_six_month === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.thirty_six_month * 100).toFixed(
                  2
                ),
          ytd:
            stats.sous_strats[nom].contrib.ytd === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.ytd * 100).toFixed(2),
          start:
            stats.sous_strats[nom].contrib.std === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.std * 100).toFixed(2),
          ann:
            stats.sous_strats[nom].contrib.ann === 0
              ? "-"
              : (stats.sous_strats[nom].contrib.ann * 100).toFixed(2),
          vol: "-",
          sharpe: "-",
          "sharpe 3M": "-",
        },
      ]);
    }
    setIsLoading(false);
  }, [nom, stats, ptf]);

  return (
    <div>
      {isLoading && stats ? (
        <h3>Loading datas...</h3>
      ) : (
        <ThemeProvider theme={darkTheme}>
          <Box
            sx={{
              "& .negative": {
                color: "red",
              },
              "& .positive": {
                color: "lightgreen",
              },
              "& .zero": {
                color: "#faf9f9",
              },
              "& .first-column-background": {
                backgroundColor: "#1727477e",
              },
            }}
          >
            <div>
              {nom.split("_").join(" ")}
              {ptf === "USA Equity Market Neutre" &&
                nom !== "Macro_USEMN" &&
                " - 14.3%"}
            </div>
            <div
              style={{
                height:
                  ptf === "USA Equity Market Neutre" && nom !== "Macro_USEMN"
                    ? 266
                    : 214,
                width: "100%",
                marginBottom: 50,
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                rowsPerPageOptions={[100]}
                labelRowsPerPage=""
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    textOverflow: "clip",
                    whiteSpace: "break-spaces",
                    lineHeight: "20px",
                    textAlign: "center",
                  },
                }}
                getCellClassName={(params) => {
                  if (
                    params.field === "id" ||
                    params.field === "sharpe" ||
                    params.field === "sharpe 3M" ||
                    params.field === "vol" ||
                    params.id === "bench"
                  ) {
                    return "";
                  }
                  if (params.value < 0) {
                    return "negative";
                  } else if (params.value > 0) {
                    return "positive";
                  } else return "white";
                }}
              />
            </div>
          </Box>
        </ThemeProvider>
      )}
    </div>
  );
}
