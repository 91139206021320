import React, { useContext, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { AccountContext } from "./Account";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isAuth, setIsAuth] = useState(true);
  const { getSession } = useContext(AccountContext);
  useEffect(() => {
    getSession().then((session) => {
      if (session) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          return (
            <>
              <Component {...props} />
            </>
          );
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
